module controllers {
    export module master {
        interface IVariableDocumentUpdateCtrlScope extends ng.IScope {
        }

        interface IVariableDocumentUpdateCtrlScopeParams extends ng.ui.IStateParamsService {
            variableDocumentId: number;
        }

        export class variableDocumentUpdateCtrl {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "$timeout",
                '$uibModal',
                'variableDocsTemplateService',
                'entityService',
                'customerService',
                '$state',
                '$stateParams',
                'uiGridConstants',
                '$transitions'
            ];

            selectedTab: number = 0;
            variableDocumentId: number = 0;
            variableDocument: interfaces.master.IVariableDocsTemplateViewModel;
            variableDocColumnTypeList: ng.resource.IResourceArray<interfaces.master.IVariableDocType>;

            breadCrumbDesc: string;

            IsLoading: boolean = false;
            variableDocHook: any;

            gridCustomerApi: uiGrid.IGridApi;

            constructor(private $scope: IVariableDocumentUpdateCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private variableDocsTemplateService: interfaces.master.IVariableDocsTemplateService,
                private entityService: interfaces.applicationcore.IEntityService,
                private customerService: interfaces.master.ICustomerService,
                private $state: ng.ui.IStateService,
                private $stateParams: IVariableDocumentUpdateCtrlScopeParams,
                private uiGridConstants: uiGrid.IUiGridConstants,
                private $transitions: ng.ui.core.ITransition
            ) {
                if ($stateParams.variableDocumentId == null) {
                    return;
                }
                this.variableDocumentId = $stateParams.variableDocumentId;

                this.variableDocHook = $transitions.onSuccess({
                    to: 'auth.MasterData.VariableDocument.Update',
                    from: 'auth.MasterData.VariableDocument.Update.**'
                }, () => {
                    return this.loadVariableDocument();
                });

                this.loadVariableDocument()
            }

            loadVariableDocument() {
                this.IsLoading = true;
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'variableDocument.update'
                },
                    () => {
                        return this.variableDocsTemplateService.getDetail(this.variableDocumentId).query((result: interfaces.master.IVariableDocsTemplateViewModel) => {
                            this.IsLoading = false;
                            this.variableDocument = result;
                            this.gvwCustomers.data = result.CustomerList;

                            if (this.variableDocument.Id < 1)
                                this.breadCrumbDesc = 'New Variable Document';
                            else
                                this.breadCrumbDesc = this.variableDocument.Description;
                        }
                            , (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                    });
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }

            AddRow() {
                if (!this.variableDocument.VariableDocumentDetail)
                    this.variableDocument.VariableDocumentDetail = <interfaces.master.IVariableDocumentDetail>{ DocumentColumns: [] };

                if (!this.variableDocument.VariableDocumentDetail.DocumentColumns)
                    this.variableDocument.VariableDocumentDetail.DocumentColumns = [];

                var count = this.variableDocument.VariableDocumentDetail.DocumentColumns.length + 1;
                if (count > 9)//Max 9 columns
                    return;


                this.variableDocument.VariableDocumentDetail.DocumentColumns.push(<interfaces.master.IVariableDocColumn>
                    {
                        Order: count,
                        Label: "New Column " + count,
                        Print: true,
                        Width: 1,
                        Type: { Id: 0, Code: "", Display: "", Description: "", Selected: true, RequiresSubType: false }
                    });
            }

            downloadObjectAsJson(exportObj) {
                var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
                var downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute("href", dataStr);
                downloadAnchorNode.setAttribute("download", "variableDocument.json");
                document.body.appendChild(downloadAnchorNode); // required for firefox
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            }

            addCustomer() {
                (<Array<any>>(this.gvwCustomers.data)).splice(0, 0, <interfaces.applicationcore.IDropdownModel>{});
            }

            loadCustomers = (searchText: string) => {
                if (this.variableDocument.Entity && this.variableDocument.Entity.Id) {
                    return this.customerService.getDropdownForVariableDocsTemplate(this.variableDocument.Entity.Id, searchText).query(() => {

                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer<ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>>();
                    defer.resolve(<ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>>[]);
                    return defer.promise;
                }
            }

            removeClient = (row: any) => {
                if (row.entity.Customer && row.entity.Customer.Id) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'variableDocument.update'
                    },
                        () => {
                            return this.customerService.setVariableDocsTemplate(row.entity.Customer.Id, 0, this.variableDocument.Entity.Id).save((result: interfaces.applicationcore.IMessageHandler) => {
                                if (result.HasErrorMessage) {
                                    this.generalService.displayMessageHandler(result);
                                } else {
                                    _.remove(this.gvwCustomers.data, (o) => { return o === row.entity });
                                    _.remove(this.variableDocument.CustomerList, (o) => { return o === row.entity.Customer });
                                    this.generalService.displayMessageHandler(result);
                                }
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                } else {
                    _.remove(this.gvwCustomers.data, (o) => { return o === row.entity });
                }
            }

            public registerGridApi(gridApi: uiGrid.IGridApi) {
                this.gridCustomerApi = gridApi;

                this.$timeout(() => {
                    this.gvwCustomers.data = this.variableDocument.CustomerList;
                });
            }

            public gvwCustomers: uiGrid.IGridOptions | any = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableSorting: true,
                useExternalSorting: false,
                enableCellEdit: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                useExternalPagination: false,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                showGridFooter: true,
                enableGridMenu: true,
                gridMenuShowHideColumns: false,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: true,
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterExcelFilename: 'VariableDocumentCustomerList.xlsx',
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "ASSIGN",
                    displayName: "",
                    field: "",
                    width: 50,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    enableSorting: false,
                    cellTemplate: `
                    <div class="GridButton center-block"> 
                        <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.variableDocumentUpdateCtrl.removeClient(row)" ng-disabled="grid.appScope.variableDocumentUpdateCtrl.variableDocument.LockdownType >= 2">
                            <span class="fa fa-close"></span>
                        </button>
                    </div>`
                }, {
                    name: "Customer",
                    displayName: "Customer",
                    field: "Customer",
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    enableFiltering: true,
                    enableSorting: true,
                    enableColumnMenu: false,
                    width: 450,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                    <form name="inputForm">
                        <gts-dropdown ui-select-wrap name="Customer-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.variableDocumentUpdateCtrl.loadCustomers(searchText)" required="true" pagination-search="true" refresh-delay="1000"  ng-disabled="grid.appScope.variableDocumentUpdateCtrl.variableDocument.LockdownType >= 2"></gts-dropdown>
                    </form>`,
                }]
            };

            Up(varDocColumn: interfaces.master.IVariableDocColumn) {
                if (!this.variableDocument.VariableDocumentDetail)
                    return;
                if (varDocColumn.Order === 1)
                    return;

                this.variableDocument.VariableDocumentDetail.DocumentColumns.splice((varDocColumn.Order - 2), 0, this.variableDocument.VariableDocumentDetail.DocumentColumns.splice(varDocColumn.Order-1, 1)[0]);

                this.variableDocument.VariableDocumentDetail.DocumentColumns[varDocColumn.Order-1].Order++;
                this.variableDocument.VariableDocumentDetail.DocumentColumns[varDocColumn.Order-2].Order--;

            }

            Down(varDocColumn: interfaces.master.IVariableDocColumn) {
                if (!this.variableDocument.VariableDocumentDetail)
                    return;
                if ((varDocColumn.Order - 1) === this.variableDocument.VariableDocumentDetail.DocumentColumns.length)
                    return;

                this.variableDocument.VariableDocumentDetail.DocumentColumns.splice((varDocColumn.Order), 0, this.variableDocument.VariableDocumentDetail.DocumentColumns.splice(varDocColumn.Order - 1, 1)[0]);

                this.variableDocument.VariableDocumentDetail.DocumentColumns[varDocColumn.Order - 1].Order--;
                this.variableDocument.VariableDocumentDetail.DocumentColumns[varDocColumn.Order].Order++;

            }

            Print(varDocColumn: interfaces.master.IVariableDocColumn) {
                if (varDocColumn.Print) {//before changed
                    varDocColumn.Width = 0;
                }
            }

            loadVariableDocColumnTypes() {
                return this.variableDocsTemplateService.getVariableDocTypes(this.variableDocument.Entity.Id).query({},
                    (result: ng.resource.IResourceArray<interfaces.master.IVariableDocType>) => {
                        this.variableDocColumnTypeList = result;
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            SaveVariableDocument(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                this.variableDocsTemplateService.save().save(this.variableDocument, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);
                    this.IsLoading = false;
                    this.variableDocumentId = Number(data.ID);

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'variableDocument.update'
                },
                    () => {
                        return this.SaveVariableDocument().then((data: boolean) => {
                            if (data && this.variableDocumentId > 0) {
                                this.loadVariableDocument();
                            }
                        });
                    });
            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'variableDocument.update'
                },
                    () => {
                        return this.SaveVariableDocument().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {

                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }
        }

        angular.module("app").controller("variableDocumentUpdateCtrl", controllers.master.variableDocumentUpdateCtrl);
    }
}